import { baseUrl } from "./BaseUrl";

export const getCodeApi = baseUrl + "/getcode";

export const checkOTPApi = baseUrl + "/checkotp";
export const getCodeLoginApi = baseUrl + "/getcode-login";
export const getLoginApi = baseUrl + "/login";
export const addTrxApi = baseUrl + "/transaction";
export const getTransactionApi = baseUrl + "/transaction-get";
export const metaRequestInsertApi = baseUrl + "/trx-apply";
export const checkUserApi = baseUrl + "/check-user";
export const getTotalUsdtApi = baseUrl + "/get-total-usdt";
export const signupUserApi = baseUrl + "/sign-up-user";
export const registerApi = baseUrl + "/register-user";
export const generateReferralApi = baseUrl + "/generate-referral-code";
export const getStageApi = baseUrl + "/get-stage";
export const getAuthApi = baseUrl + "/jwtTokenVerify";
export const getUserReferralCode = baseUrl + "/get-user-referral-code";
export const getTopReferral = baseUrl + "/get-top-referral-data";
